import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql, StaticQuery } from 'gatsby'
import Layout from '../components/layout'
import { kebabCase } from 'lodash'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

class CategoryRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <div key={post.node.fields.slug} className="post">
        <article
            key={post.id}
          >
            <header>
              {post.node.frontmatter.authorimage ? (
                <div>
                  <div className="photo">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.node.frontmatter.authorimage,
                        alt: `featured image thumbnail for post ${post.node.frontmatter.authorname}`,
                      }}
                    />
                  </div>
                  <span className="underline"></span>
                </div>
              ) : null}
              <p className="tags">
                <Link to={`/category/${kebabCase(post.node.frontmatter.category)}/`}>
                  {post.node.frontmatter.category}
                </Link>
                <span className="separator"> / </span>
                <span>
                  {(post.node.frontmatter.date).split("/").join(".")}
                </span>
              </p>
            </header>
            <h3><Link className="hover:text-theme" to={post.node.fields.slug}>{post.node.frontmatter.title}</Link></h3>
            <div className="text-16 mb-8">
              {post.node.excerpt}
            </div>
            <Link className="read-more" to={post.node.fields.slug}>Read More</Link>
          </article>
      </div>
    ))
    const category = this.props.pageContext.category
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const categoryHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } in “${category}”`

    return (
      <StaticQuery
      query={graphql`
        query CategoriesPage($category: String) {
          site {
            siteMetadata {
              title
            }
          }
          allMarkdownRemark(
            limit: 1000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { category: { eq: $category }, templateKey: { eq: "blog-post" } } }
          ) {
            totalCount
            edges {
              node {
                excerpt(pruneLength: 400)
                fields {
                  slug
                }
                frontmatter {
                  title
                  date
                  authorbio
                  authorname
                  authorimage {
                    childImageSharp {
                      fluid(maxWidth: 85, quality: 85) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                    publicURL
                  }
                  category
                }
              }
            }
          }
        }
      `}
      render={data => (
      <Layout>
        <section className="max-w-blog mx-auto px-8 py-82">
          <Helmet title={`${category} | ${title}`} />
          <h3 className="cats-header">{categoryHeader}</h3>
          <div className="blog-entries">
            <div className="posts-container">{postLinks}</div>
          </div>
          <p className="cats-header">
            <Link to="/category/">Browse all categories</Link>
          </p>
        </section>
      </Layout>
      )}
      />
    )
  }
}

export default CategoryRoute
